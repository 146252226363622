<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ action }} Data</b-card-title>
        <b-card-title class="text-primary">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="btn-icon"
            size="sm"
            :to="{ name: 'mstPeran' }"
          >
            <feather-icon icon="ArrowLeftIcon" />
          </b-button>
        </b-card-title>
      </b-card-header>

      <b-card-body>
        <b-form class="needs-validation" @submit.prevent="submitForm">
          <b-form-row>
            <b-col md="10" offset-md="1">
              <!-- input label -->
              <b-form-group label="Label" label-for="label">
                <b-form-input
                  id="label"
                  v-model="label"
                  :state="validateState(v$.label)"
                />
                <b-form-invalid-feedback
                  v-for="(error, index) of v$.label.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-col cols="12" class="text-right pr-0">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  variant="outline-secondary"
                  class="mr-1"
                  @click="resetForm"
                >
                  Reset
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                >
                  <div v-if="isSubmit">
                    <b-spinner small />
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div v-else>Simpan</div>
                </b-button>
              </b-col>
            </b-col>
          </b-form-row>
        </b-form>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BForm,
  BFormRow,
  BCol,
  BFormInput,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BCardHeader,
  BCardBody,
  BButton,
  BFormGroup,
  BCardTitle,
  BSpinner,
} from "bootstrap-vue";
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { ref, computed, watchEffect } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";

export default {
  setup(props, context) {
    // console.log(g);
    // let app = getCurrentInstance()

    const g = context.root;

    var isSubmit = ref(false);
    const router = g.$router;
    const $http = g.$http;

    //form name
    var label = ref("");

    watchEffect(() => {
      label.value = props.labelVal;
    });

    // validation
    const requiredMsg = "tidak boleh kosong";
    const $externalResults = ref({});
    const rules = computed(() => ({
      label: {
        required: helpers.withMessage("Label " + requiredMsg, required),
      },
    }));

    const v$ = useVuelidate(
      rules,
      { label },
      { $externalResults, $autoDirty: true }
    );

    const submitForm = async () => {
      // console.log(router.push({name: 'mjmn-pengguna'}));
      isSubmit.value = true;
      const isFormCorrect = await v$.value.$validate();
      // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
      //jika error validasi
      if (!isFormCorrect) {
        isSubmit.value = false;
        return;
      }

      // mapping data
      let data = {
        label: label.value,
        id: props.id,
      };

      $http({
        url: "/mst/peran/submit/" + props.action,
        data,
        method: "post",
        headers: {
          Authorization: "Bearer " + g.$cookies.get("token"),
        },
      })
        .then((res) => {
          router.push({ name: "mstPeran" });
        })
        .catch((err) => {
          isSubmit.value = false;
          let showError = err.response
            ? err.response.data
            : err.request
            ? err.request
            : "Error" + err.message;

          g.$bvToast.toast(showError || "Something Wrong", {
            title: `Error Submit Form`,
            variant: "danger",
            solid: true,
          });
        });
    };

    const validateState = (item) => {
      const { $dirty, $error } = item;
      return !$dirty ? null : $error ? false : null;
    };

    const resetForm = () => {
      label.value = "";
    };

    return {
      v$,
      $externalResults,
      isSubmit,
      label,
      resetForm,
      validateState,
      submitForm,
    };
  },
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BForm,
    BFormRow,
    BCol,
    BFormInput,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BCardHeader,
    BCardBody,
    BButton,
    BFormGroup,
    BCardTitle,
    BSpinner,
  },
  props: {
    action: String,
    labelVal: String,
    id: String,
  },
};
</script>

<style lang="scss" scope>
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>